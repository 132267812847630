var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "시나리오 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "riskHazardPicture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-success",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openHazardPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "picture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-primary",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openImprPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "ramRiskLevelName"
                  ? [
                      _c(
                        "q-chip",
                        {
                          style: {
                            color:
                              props.row[col.name] === "상"
                                ? "red"
                                : props.row[col.name] === "중"
                                ? "orange"
                                : "green",
                          },
                          attrs: { outline: "", square: "" },
                        },
                        [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                      ),
                    ]
                  : col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "ramThirdAssessScenarioId",
                          ibmTaskTypeCd: "ITT0000013",
                          ibmTaskUnderTypeCd: "ITTU000015",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }